import { Fragment, FunctionalComponent, h } from "preact";
import { Link, route, useRouter } from "preact-router";
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import MenuSettingsIcon from "../../assets/svg/MenuSettingsIcon";
import MenuAnalyticsIcon from "../../assets/svg/MenuAnalyticsIcon";
import MenuExperienceCreatorIcon from "../../assets/svg/MenuExperienceCreatorIcon";
import MenuKnowledgeManagerIcon from "../../assets/svg/MenuKnowledgeManagerIcon";
import MenuLogoIcon from "../../assets/svg/MenuLogoIcon";
import MenuAiTrainingIcon from "../../assets/svg/MenuAiTrainingIcon";
import PersonasIcon from "../../assets/svg/PersonasIcon";
import CloseSidebar from "../../assets/svg/CloseSidebar";
import OpenSidebar from "../../assets/svg/OpenSidebar";
import style from "./sidebarStyles.scss";
import { Roles } from "../../models/roles";
import { useState } from "preact/hooks";
import { useUserAuthContext } from "../../context/UserAuthContext";
import MenuList from "./MenuList";
import QuestionIcon from "../../assets/svg/QuestionIcon";
import { PricingTable } from "../pricing-table/PricingTable";
import { blue } from "@mui/material/colors";
import { useSwitchPortalContext } from "../../context/SwitchPortalContext";
import { useSnackBar } from "../../context/SnackBarContext";
import DeletePortal from "./DeletePortal";

interface IProps {
  currentUserRole: Roles;
  showPricingTable: boolean;
  setShowPricingTable: (flag: boolean) => void;
}

const Sidebar: FunctionalComponent<IProps> = ({
  currentUserRole,
  showPricingTable,
  setShowPricingTable,
}) => {
  const routerContext = useRouter();
  const { userDetails } = useUserAuthContext();
  const { havePortalName } = useSwitchPortalContext();
  const { workspaceId } = useWorkspaceContext();
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLDivElement | null>(null);
  const [workspaceAnchorEl, setWorkspaceAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [showOpen, setShowOpen] = useState(false);
  const [presentWorkspace, setPresentWorspace] = useState("");
  const [showPortalModal, setShowPortalModal] = useState<string>("");
  const handlePortalDeleteModal = () => {
    setShowPortalModal("");
  };
  const navigateToWorkspacePage = () => {
    if (havePortalName) {
      setShowPortalModal(`/workspace/${workspaceId}`);
      return;
    }
    route(`/workspace/${workspaceId}`);
  };
  const navigateToPortalsPage = () => {
    if (havePortalName) {
      setShowPortalModal(`/workspace/${workspaceId}/portals`);
      return;
    }
    route(`/workspace/${workspaceId}/portals`);
  };

  const navigateToAnalyticsPage = () => {
    if (havePortalName) {
      setShowPortalModal(`/stats/workspace/${workspaceId}`);
      return;
    }
    route(`/stats/workspace/${workspaceId}`);
  };

  const navigateToSettingsPage = () => {
    if (havePortalName) {
      setShowPortalModal(`/workspace/${workspaceId}/settings`);
      return;
    }
    route(`/workspace/${workspaceId}/settings`);
  };

  const navigateToAiTriningPage = () => {
    if (havePortalName) {
      setShowPortalModal(`/workspace/${workspaceId}/ai-training`);
      return;
    }
    route(`/workspace/${workspaceId}/ai-training`);
  };
  const navigateToPersonasPage = () => {
    if (havePortalName) {
      setShowPortalModal(`/workspace/${workspaceId}/personas`);
      return;
    }
    route(`/workspace/${workspaceId}/personas`);
  };
  return (
    <Fragment>
      <DeletePortal
        handlePortalDeleteModal={handlePortalDeleteModal}
        showPortalModal={showPortalModal}
      />
      <PricingTable
        isVisible={showPricingTable}
        onClose={() => setShowPricingTable(false)}
      />

      <div
        className={style.sidebar}
        style={{
          width: isOpen ? "270px" : "100px",
          transition: "width 0.2s",
          alignItems: isOpen ? "flex-start" : "center",
          padding: isOpen ? "0px 10px" : "0px",
        }}
        onMouseOver={() => setShowOpen(true)}
        onMouseLeave={() => setShowOpen(false)}
      >
        {showOpen &&
          (isOpen ? (
            <div onClick={() => setIsOpen(!isOpen)} className={style.closeIcon}>
              <CloseSidebar />
            </div>
          ) : (
            <div onClick={() => setIsOpen(!isOpen)} className={style.openIcon}>
              <OpenSidebar />
            </div>
          ))}

        <div
          className={style.logoWrapper}
          style={{
            paddingLeft: isOpen ? "20px" : "0px",
            cursor: "pointer",
          }}
          onClick={navigateToPortalsPage}
        >
          <MenuLogoIcon isOpen={isOpen} />
        </div>
        {isOpen ? (
          <List
            component="nav"
            className={style.navWrapper}
            style={{
              width: isOpen ? "230px" : "70px",
              transition: "width 0.3s",
            }}
          >
            <Link onClick={(e: any) => setWorkspaceAnchorEl(e.currentTarget)}>
              <ListItemButton
                className={style.tabButton}
                selected={true}
                sx={{
                  backgroundColor: workspaceAnchorEl && "#063F6E !important",
                  ":hover": { backgroundColor: "#063F6E77" },
                  height: 60,
                  py: 0,
                  pl: "18px !important",
                  mt: "0px !important",
                  mb: 2,
                  width: "100%",
                  whiteSpace: "nowrap", // Prevents text wrapping
                  overflow: "hidden", // Hides overflowing text
                }}
              >
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <Avatar
                    sx={{ bgcolor: blue[300] }}
                    alt={userDetails?.userName}
                    round={true}
                    size={"small"}
                  >
                    {presentWorkspace[0]}
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    color: "#fff",
                    fontSize: 16,
                    fontFamily: "Manrope",
                    fontWeight: 400,
                    ml: 1,
                  }}
                  title={presentWorkspace.length > 13 ? presentWorkspace : ""}
                  primary={
                    presentWorkspace.length > 13
                      ? `${presentWorkspace.slice(0, 13)}...`
                      : presentWorkspace
                  }
                />
                <ListItemIcon sx={{ minWidth: 20 }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8463 18.5655L8 14.7192L8.71925 14L11.8463 17.127L14.9733 14L15.6925 14.7192L11.8463 18.5655Z"
                      fill="white"
                    />
                    <path
                      d="M11.8461 4.99999L15.6924 8.84624L14.9731 9.56549L11.8461 6.43849L8.71913 9.56549L7.99988 8.84624L11.8461 4.99999Z"
                      fill="white"
                    />
                  </svg>
                </ListItemIcon>
              </ListItemButton>
            </Link>
            {currentUserRole === Roles.RoleAgent ? null : (
              <>
                <Link onClick={navigateToPortalsPage}>
                  <ListItemButton
                    className={style.tabButton}
                    selected={
                      routerContext[0].path ===
                        "/workspace/:workspaceId/portals" ||
                      routerContext[0].path ===
                        "/workspace/:workspaceId/portals/:portalId"
                    }
                    sx={{
                      backgroundColor:
                        routerContext[0].path ===
                          "/workspace/:workspaceId/portals" ||
                        routerContext[0].path ===
                          "/workspace/:workspaceId/portals/:portalId"
                          ? "#063F6E !important"
                          : "inherit",
                      ":hover": { backgroundColor: "#063F6E77" },
                      height: 60,
                      py: 0,
                      pl: "18px !important",
                      width: "100%",
                      whiteSpace: "nowrap", // Prevents text wrapping
                      overflow: "hidden", // Hides overflowing text
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <MenuExperienceCreatorIcon
                        sx={{ color: "#fff", width: 23 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        color: "#fff",
                        fontSize: 16,
                        fontFamily: "Manrope",
                        fontWeight: 400,
                      }}
                      primary={"Experience Creator"}
                    />
                  </ListItemButton>
                </Link>
                <Link onClick={navigateToWorkspacePage}>
                  <ListItemButton
                    className={style.tabButton}
                    selected={
                      routerContext[0].path === "/workspace/:workspaceId" ||
                      routerContext[0].path ===
                        "/workspace/:workspaceId/kapp/:kappId"
                    }
                    sx={{
                      backgroundColor:
                        routerContext[0].path === "/workspace/:workspaceId" ||
                        routerContext[0].path ===
                          "/workspace/:workspaceId/kapp/:kappId"
                          ? "#063F6E !important"
                          : "inherit",
                      ":hover": { backgroundColor: "#063F6E77" },
                      height: 60,
                      py: 0,
                      pl: "18px !important",
                      width: "100%",
                      whiteSpace: "nowrap", // Prevents text wrapping
                      overflow: "hidden", // Hides overflowing text
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <MenuKnowledgeManagerIcon
                        sx={{ color: "#fff", width: 23 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        color: "#fff",
                        fontSize: 16,
                        fontFamily: "Manrope",
                        fontWeight: 400,
                      }}
                      primary={"Knowledge Manager"}
                    />
                  </ListItemButton>
                </Link>
                {/* {currentUserRole === Roles.RoleContentManager ? null : ( */}
                <Link onClick={navigateToPersonasPage}>
                  <ListItemButton
                    className={style.tabButton}
                    selected={
                      routerContext[0].path ===
                      "/workspace/:workspaceId/personas"
                    }
                    sx={{
                      backgroundColor:
                        routerContext[0].path ===
                        "/workspace/:workspaceId/personas"
                          ? "#063F6E !important"
                          : "inherit",
                      ":hover": { backgroundColor: "#063F6E77" },
                      height: 60,
                      py: 0,
                      pl: "18px !important",
                      width: "100%",
                      whiteSpace: "nowrap", // Prevents text wrapping
                      overflow: "hidden", // Hides overflowing text
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <PersonasIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        color: "#fff",
                        fontSize: 16,
                        fontFamily: "Manrope",
                        fontWeight: 400,
                      }}
                      primary={<h1>Personas</h1>}
                    />
                  </ListItemButton>
                </Link>
                {/* // )} */}
                <Link onClick={navigateToAnalyticsPage}>
                  <ListItemButton
                    className={style.tabButton}
                    selected={
                      routerContext[0].path === "/stats/workspace/:workspaceId"
                    }
                    sx={{
                      backgroundColor:
                        routerContext[0].path ===
                        "/stats/workspace/:workspaceId"
                          ? "#063F6E !important"
                          : "inherit",
                      ":hover": { backgroundColor: "#063F6E77" },
                      height: 60,
                      py: 0,
                      pl: "18px !important",
                      width: "100%",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <MenuAnalyticsIcon sx={{ color: "#fff", width: 23 }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        color: "#fff",
                        fontSize: 16,
                        fontFamily: "Manrope",
                        fontWeight: 400,
                      }}
                      primary={"Analytics"}
                    />
                  </ListItemButton>
                </Link>
                {currentUserRole === Roles.RoleContentManager ? null : ( // remove "|| true" when AI training is ready
                  <Link onClick={navigateToAiTriningPage}>
                    <ListItemButton
                      className={style.tabButton}
                      selected={
                        routerContext[0].path ===
                        "/workspace/:workspaceId/ai-training"
                      }
                      sx={{
                        backgroundColor:
                          routerContext[0].path ===
                          "/workspace/:workspaceId/ai-training /"
                            ? "#063F6E !important"
                            : "inherit",
                        ":hover": { backgroundColor: "#063F6E77" },
                        height: 60,
                        py: 0,
                        pl: "18px !important",
                        width: "100%",
                        whiteSpace: "nowrap", // Prevents text wrapping
                        overflow: "hidden", // Hides overflowing text
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <MenuAiTrainingIcon sx={{ color: "#fff", width: 23 }} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          color: "#fff",
                          fontSize: 16,
                          fontFamily: "Manrope",
                          fontWeight: 400,
                        }}
                        primary={<h1>AI Training </h1>}
                      />
                    </ListItemButton>
                  </Link>
                )}
                {currentUserRole === Roles.RoleContentManager ? null : (
                  <Link onClick={navigateToSettingsPage}>
                    <ListItemButton
                      className={style.tabButton}
                      selected={
                        routerContext[0].path ===
                        "/workspace/:workspaceId/settings"
                      }
                      sx={{
                        backgroundColor:
                          routerContext[0].path ===
                          "/workspace/:workspaceId/settings/"
                            ? "#063F6E !important"
                            : "inherit",
                        ":hover": { backgroundColor: "#063F6E77" },
                        height: 60,
                        py: 0,
                        pl: "18px !important",
                        width: "100%",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <MenuSettingsIcon sx={{ color: "#fff", width: 23 }} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          color: "#fff",
                          fontSize: 16,
                          fontFamily: "Manrope",
                          fontWeight: 400,
                        }}
                        primary={"Settings"}
                      />
                    </ListItemButton>
                  </Link>
                )}
              </>
            )}
          </List>
        ) : (
          <List
            component="nav"
            className={style.navWrapper}
            style={{
              width: isOpen ? "230px" : "70px",
              transition: "width 0.1s",
            }}
          >
            <Link onClick={(e: any) => setWorkspaceAnchorEl(e.currentTarget)}>
              <ListItemButton
                className={style.tabButton}
                selected={true}
                sx={{
                  backgroundColor: workspaceAnchorEl && "#063F6E !important",
                  ":hover": { backgroundColor: "#063F6E77" },
                  height: 60,
                  py: 0,
                  // pl: "18px !important",
                  marginTop: "0px !important",
                  marginBottom: "10px !important",
                  width: "100%",
                }}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Avatar
                    sx={{ bgcolor: blue[300] }}
                    alt={userDetails?.userName}
                    round={true}
                    size={"10"}
                  >
                    {userDetails?.userName[0]}
                  </Avatar>
                </ListItemIcon>
              </ListItemButton>
            </Link>
            {currentUserRole === Roles.RoleAgent ? null : (
              <>
                <Link onClick={navigateToPortalsPage}>
                  <ListItemButton
                    selected={
                      routerContext[0].path ===
                        "/workspace/:workspaceId/portals" ||
                      routerContext[0].path ===
                        "/workspace/:workspaceId/portals/:portalId"
                    }
                    className={style.tabButton}
                    sx={{
                      backgroundColor:
                        routerContext[0].path ===
                          "/workspace/:workspaceId/portals" ||
                        routerContext[0].path ===
                          "/workspace/:workspaceId/portals/:portalId"
                          ? "#063F6E !important"
                          : "inherit",
                      ":hover": { backgroundColor: "#063F6E77" },
                      height: 60,
                      py: 0,
                      marginTop: "0px  !important",
                      // pl:  "18px !important",
                      // width: isOpen ? "100%" : "70px",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <MenuExperienceCreatorIcon
                      // sx={{ color: "#fff", width: 23 }}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </Link>
                <Link onClick={navigateToWorkspacePage}>
                  <ListItemButton
                    className={style.tabButton}
                    selected={
                      routerContext[0].path === "/workspace/:workspaceId" ||
                      routerContext[0].path ===
                        "/workspace/:workspaceId/kapp/:kappId"
                    }
                    sx={{
                      backgroundColor:
                        routerContext[0].path === "/workspace/:workspaceId" ||
                        routerContext[0].path ===
                          "/workspace/:workspaceId/kapp/:kappId"
                          ? "#063F6E !important"
                          : "inherit",
                      ":hover": { backgroundColor: "#063F6E77" },
                      height: 60,
                      py: 0,
                      // pl: "18px !important",
                      width: "100%",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <MenuKnowledgeManagerIcon
                        sx={{ color: "#fff", width: 23 }}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </Link>
                <Link onClick={navigateToPersonasPage}>
                  <ListItemButton
                    className={style.tabButton}
                    selected={
                      routerContext[0].path ===
                      "/workspace/:workspaceId/personas"
                    }
                    sx={{
                      backgroundColor:
                        routerContext[0].path ===
                        "/workspace/:workspaceId/personas"
                          ? "#063F6E !important"
                          : "inherit",
                      ":hover": { backgroundColor: "#063F6E77" },
                      height: 60,
                      py: 0,
                      // pl: "18px !important",
                      width: "100%",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <PersonasIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </Link>
                <Link onClick={navigateToAnalyticsPage}>
                  <ListItemButton
                    className={style.tabButton}
                    selected={
                      routerContext[0].path === "/stats/workspace/:workspaceId"
                    }
                    sx={{
                      backgroundColor:
                        routerContext[0].path ===
                        "/stats/workspace/:workspaceId"
                          ? "#063F6E !important"
                          : "inherit",
                      ":hover": { backgroundColor: "#063F6E77" },
                      height: 60,
                      py: 0,
                      // pl: "18px !important",
                      width: "100%",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <MenuAnalyticsIcon sx={{ color: "#fff", width: 23 }} />
                    </ListItemIcon>
                  </ListItemButton>
                </Link>
                {currentUserRole === Roles.RoleContentManager ? null : ( // remove "|| true" when AI training is ready
                  <Link onClick={navigateToAiTriningPage}>
                    <ListItemButton
                      className={style.tabButton}
                      selected={
                        routerContext[0].path ===
                        "/workspace/:workspaceId/ai-training"
                      }
                      sx={{
                        backgroundColor:
                          routerContext[0].path ===
                          "/workspace/:workspaceId/ai-training /"
                            ? "#063F6E !important"
                            : "inherit",
                        ":hover": { backgroundColor: "#063F6E77" },
                        height: 60,
                        py: 0,
                        // pl: "18px !important",
                        width: "100%",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <MenuAiTrainingIcon sx={{ color: "#fff", width: 23 }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </Link>
                )}
                {currentUserRole === Roles.RoleContentManager ? null : (
                  <Link onClick={navigateToSettingsPage}>
                    <ListItemButton
                      className={style.tabButton}
                      selected={
                        routerContext[0].path ===
                        "/workspace/:workspaceId/settings"
                      }
                      sx={{
                        backgroundColor:
                          routerContext[0].path ===
                          "/workspace/:workspaceId/settings/"
                            ? "#063F6E !important"
                            : "inherit",
                        ":hover": { backgroundColor: "#063F6E77" },
                        height: 60,
                        py: 0,
                        // pl: "18px !important",
                        width: "100%",
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <MenuSettingsIcon sx={{ color: "#fff", width: 23 }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </Link>
                )}
              </>
            )}
          </List>
        )}
        <div className={style.accountWrapper}>
          <List
            component="nav"
            className={style.navWrapper}
            style={{
              width: isOpen ? "230px" : "70px",
              transition: "width 1s",
            }}
          >
            <ListItemButton
              onClick={(e: any) => setHelpAnchorEl(e.currentTarget)}
              className={style.tabButton}
              sx={{
                ":hover": { backgroundColor: "#063F6E77" },
                height: 60,
                py: 0,
                marginTop: "0px  !important",
                width: "100%",
                // pl: "18px !important",
              }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <QuestionIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  color: "#fff",
                  fontSize: 16,
                  fontFamily: "Manrope",
                  fontWeight: 400,
                }}
                style={{
                  opacity: isOpen ? 1 : 0,
                  transition: " opacity 0.5s",
                  display: isOpen ? "block" : "none",
                }}
                primary={"Get Help"}
              />
            </ListItemButton>
          </List>
        </div>
      </div>
      <MenuList
        helpAnchorEl={helpAnchorEl}
        setHelpAnchorEl={setHelpAnchorEl}
        setWorkspaceAnchorEl={setWorkspaceAnchorEl}
        workspaceAnchorEl={workspaceAnchorEl}
        currentUserRole={currentUserRole}
        setShowPricingTable={setShowPricingTable}
        setPresentWorspace={setPresentWorspace}
      />
    </Fragment>
  );
};

export default Sidebar;
