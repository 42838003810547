import { FunctionalComponent, h, Fragment } from "preact";
import Header from "../header";
import Content from "../content";
import Sidebar from "../sidebar/Sidebar";
import styles from "./styles.module.scss";
import { useEffect, useState } from "preact/hooks";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import { Roles } from "../../models/roles";
import UpgradeSvg from "../../assets/svg/Upgrade";
import { useUserAuthContext } from "../../context/UserAuthContext";
import { LoadingProgressStatus } from "../../models/loading-progress-enum";
import { getSharepointAuthUrl } from "../../api/sharepoint-api";
import { LinearProgress } from "@mui/material";
import { useFileProgressContext } from "../../context/fileUploadContext";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
interface IProps {}

const Layout: FunctionalComponent<IProps> = ({ children }) => {
  const {
    passMaxLimit,
    showSharepointWarning,
    currentUserRole,
    plan,
    workspaceId,
  } = useWorkspaceContext();
  const uppyContext = useFileProgressContext();
  const [headerHight, setHeaderHight] = useState<number>(68);
  const [mainContentHeight, setMainContentHeight] = useState<string>(
    `calc(100vh - ${headerHight}px)`
  );
  const [showPricingTable, setShowPricingTable] = useState(false);
  const {
    loadingStatus,
    userDetails,
    billingUrl,
    logOutFromEditor,
    setLoadingStatus,
  } = useUserAuthContext();

  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (loadingStatus === LoadingProgressStatus.INIT) {
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.PLATFORM_LOADED) {
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.AUTH2_LOADED) {
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.GOOGLE_AUTH_INITILIZED) {
      //last step in login page
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.USER_COMPANY_LOADED) {
    } else if (loadingStatus === LoadingProgressStatus.LOAD_PAGE_DATA) {
      setNumber(number + 20);
    } else if (loadingStatus === LoadingProgressStatus.DONE) {
      const num = 100 - number;
      setNumber(number < 99 || num >= 0 ? number + num : 100);
    }
  }, [loadingStatus]);

  useEffect(() => {
    //if its a user agent free the menu to load
    if (currentUserRole === Roles.RoleAgent) {
      setLoadingStatus(LoadingProgressStatus.DONE);
    }
  }, [currentUserRole]);

  useEffect(() => {
    setTimeout(() => {
      if (number > 99) {
        setNumber(0);
      }
    }, 500);
  }, [window.location.pathname, number]);
  //const headerHight = 68
  // const mainContentHeight = `calc(100vh - ${headerHight}px)`

  useEffect(() => {
    if (passMaxLimit) {
      setHeaderHight(68 + 31);
    } else {
      setHeaderHight(68);
    }
  }, [passMaxLimit]);

  useEffect(() => {
    setMainContentHeight(`100vh`);
    // setMainContentHeight(`calc(100vh - ${headerHight}px)`);
  }, [headerHight]);
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // Customize the message for some browsers
      const message =
        "Are you sure you want to leave this page? Changes you made may not be saved!!.";
      event.preventDefault(); // For modern browsers
      event.returnValue = message; // For older browsers
      return message; // Some browsers require this return value
    };

    // Attach the event listener
    if (uppyContext?.uppyInst) {
      console.log(window.addEventListener("beforeunload", handleBeforeUnload));
    }
  }, [uppyContext?.uppyInst]);
  return (
    <div className={styles.container}>
      {uppyContext?.uppyInst ? (
        <Dashboard
          id="inline-dashboard"
          target="#uppy-dashboard-container" // A container that persists across routes
          inline={true}
          uppy={uppyContext.uppyInst}
          metaFields={[{ id: "name", name: "Name", placeholder: "File name" }]}
          width={"auto"}
          height={"auto"}
          showProgressDetails={true}
          proudlyDisplayPoweredByUppy={false}
          fileManagerSelectionType="folders"
        />
      ) : null}
      <Sidebar
        currentUserRole={currentUserRole}
        showPricingTable={showPricingTable}
        setShowPricingTable={setShowPricingTable}
      />
      <div className={styles.container__content}>
        {number !== 0 ? (
          <div className={styles.progressBar}>
            <LinearProgress
              variant="determinate"
              value={number}
              sx={{ backgroundColor: "#E7EAEF", height: "2px" }}
            />
          </div>
        ) : null}
        {passMaxLimit && (
          <div className={styles.limitBar}>
            You have reached your account quota. Please{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setShowPricingTable(true);
              }}
            >
              Upgrade
            </a>
            !
          </div>
        )}

        {showSharepointWarning ? (
          <div className={styles.limitBar}>
            Your Sharepoint session has expired, file sync has been paused.{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={async () => {
                const { url } = await getSharepointAuthUrl(
                  workspaceId,
                  userDetails.userId,
                  userDetails.token
                );

                //window.open(url, "_blank");
                const popup = window.open(url, "popup", "width=600,height=600");

                popup.onbeforeunload = function () {
                  window.location.reload();
                };
              }}
            >
              Renew Sharepoint Session
            </a>
            !
          </div>
        ) : null}
        {/* {(currentUserRole === Roles.RoleTeamManager ||
          typeof currentUserRole === "undefined") &&
          (plan === "free" ||
            plan === "business_plus" ||
            plan === "evaluation") && (
            <div
              className={styles.header__content__withupgrade__button}
              onClick={() => {
                if (plan === "free") {
                  setShowPricingTable(true);
                } else {
                  window.open("https://korra.ai/upgrade_request");
                }
              }}
            >
              <UpgradeSvg />
              Upgrade Plan
            </div>
          )} */}
        {/* <Header height={headerHight} showLimitBar={passMaxLimit} showSharepointWarning={showSharepointWarning} /> */}
        {currentUserRole === Roles.RoleAgent ? null : (
          <Content height={mainContentHeight}>{children}</Content>
        )}
      </div>
      {/*<PrimaryModal />*/}
    </div>
  );
};

export default Layout;
