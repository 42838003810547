import { getDashboardHost } from "../configs/env";

export const getWorkspaseAliasById = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<string> => {
  const url = new URL(
    `${getDashboardHost()}/alias-by-id/${workspaceId}?userId=${userId}`
  );

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  });

  const workspaseAlias: any = await res.json();

  return workspaseAlias?.data;
};

export const getAliasById = async (
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string
): Promise<any> => {
  const url = new URL(
    `${getDashboardHost()}/alias-by-ids/${workspaceId}/${portalId}?userId=${userId}`
  );

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  });

  const alias: any = await res.json();

  return alias?.data;
};

export const updateWorkspaceAlias = async (
  workspaceAlias: string,
  workspaceId: string,
  userId: string,
  token: string
): Promise<any> => {
  const url = new URL(
    `${getDashboardHost()}/update-workspace-alias/${workspaceId}?userId=${userId}`
  );

  const body = {
    workspaceAlias,
    userId,
    workspaceId,
  };

  const response = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: JSON.stringify(body),
    mode: "cors",
  });

  const afterResponse: any = await response.json();

  return afterResponse?.data;
};

export const updatePortalAlias = async (
  portalAlias: string,
  portalId: string,
  workspaceId: string,
  userId: string,
  token: string
): Promise<any> => {
  const url = new URL(
    `${getDashboardHost()}/update-portal-alias/${workspaceId}?userId=${userId}`
  );

  const body = {
    portalAlias,
    portalId,
    workspaceId,
    userId,
  };

  const response = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: JSON.stringify(body),
    mode: "cors",
  });

  const afterResponse: any = await response.json();

  return afterResponse?.data;
};
