import { FunctionalComponent, h } from "preact";
import styles from "./sidebarStyles.scss";
import ModalWindow from "../ModalWindow/ModalWindow";
import Button from "../buttons/button";
import { useState } from "preact/hooks";
import { useUserAuthContext } from "../../context/UserAuthContext";
import { deletePortal } from "../../api/portal-api";
import { useSwitchPortalContext } from "../../context/SwitchPortalContext";
import { LogUserEvent } from "../../api/stats-api";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import { route } from "preact-router";
interface IProps {
  handlePortalDeleteModal: () => void;
  showPortalModal: string;
}
const DeletePortal: FunctionalComponent<IProps> = (props) => {
  const { workspaceId } = useWorkspaceContext();
  const { handlePortalDeleteModal, showPortalModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { userDetails } = useUserAuthContext();
  const { havePortalName, handleHavePortalName } = useSwitchPortalContext();
  const handleDeletePortal = async () => {
    try {
      setIsLoading(true);
      await deletePortal(
        workspaceId,
        havePortalName,
        userDetails.userId,
        userDetails?.token
      );
      const description = `portal with id ${havePortalName} was deleted`;
      await LogUserEvent(
        workspaceId,
        "N/A",
        userDetails.userId,
        userDetails.userEmail,
        "DeleteExperience",
        description,
        userDetails.token
      );
      setIsLoading(false);
      route(showPortalModal);
      handlePortalDeleteModal();
      handleHavePortalName(null);
    } catch (error: any) {
      console.error(error.message);
    }
  };
  return (
    <ModalWindow
      close={() => handlePortalDeleteModal()}
      isVisibleModal={showPortalModal ? true : false}
      className={styles.modalWrapper}
    >
      <p className={styles.titleContainer__text}>
        Changes you made may not be saved.
      </p>
      <div className={styles.buttonContainer}>
        <span className={styles.buttonContainer__buttons}>
          <Button
            title={"Cancel"}
            color={"white"}
            tabIndex={0}
            onPress={() => handlePortalDeleteModal()}
            onKeyPress={() => {
              handlePortalDeleteModal();
            }}
            disabled={isLoading}
          />
          <Button
            title={`Leave`}
            color={"blue"}
            tabIndex={1}
            onPress={handleDeletePortal}
            processing={isLoading}
            disabled={isLoading}
          />
        </span>
      </div>
    </ModalWindow>
  );
};

export default DeletePortal;
