import { FunctionalComponent, h } from "preact";
import styles from "./sidebarStyles.scss";
import ModalWindow from "../ModalWindow/ModalWindow";
import Button from "../buttons/button";
import { useRef, useState } from "preact/hooks";
import Input from "../input";
import { isAlphaNumeric } from "../../utils";
interface IProps {
  handleRenameModal: () => void;
  showRename: boolean;
  workspaceAlias: string;
  setWorkspaceAlias: (value: string) => void;
  nonUniqAlias: boolean;
  isLoading: boolean;
  handleWorkspaceAliasSubmit: any;
}
const RenameModal: FunctionalComponent<IProps> = (props) => {
  const input = useRef(null);
  const {
    handleRenameModal,
    showRename,
    workspaceAlias,
    setWorkspaceAlias,
    nonUniqAlias,
    handleWorkspaceAliasSubmit,
    isLoading,
  } = props;
  const [renameKnowledgeSpaceWarning, setRenameKnowledgeSpaceWarning] =
    useState<boolean>(false);
  const [isChange, setIsChange] = useState(false);
  const handleSubmit = () => {
    console.log("isAlphaNumeric(workspaceAlias)");
    console.log(isAlphaNumeric(workspaceAlias));
    if (!isAlphaNumeric(workspaceAlias)) {
      setRenameKnowledgeSpaceWarning(true);
      return;
    } else {
      setRenameKnowledgeSpaceWarning(false);
      handleWorkspaceAliasSubmit();
    }
  };
  return (
    <ModalWindow
      close={() => handleRenameModal()}
      isVisibleModal={showRename}
      className={styles.modalWrapper}
    >
      <div
        className={styles.titleContainer}
        style={{
          width: "90%",
        }}
      >
        <p className={styles.titleContainer__title}>Rename Workspace</p>
        <p className={styles.titleContainer__description}>
          Here you can rename your Knowledge Space name. NOTICE: This action
          will change your experience portal URLs. Please use only lowercase
          letters and digits.
        </p>
      </div>
      <div className={styles.uploadContainer}>
        <p className={styles.uploadContainer__title}>Space Name</p>
      </div>
      <div className={styles.contentContainer}>
        <div>
          <Input
            ref={input}
            width={"100%"}
            height={"46px"}
            placeholder={"Type your Space Name"}
            value={workspaceAlias}
            fieldBorder={
              renameKnowledgeSpaceWarning || !workspaceAlias ? true : false
            }
            onChange={(event: any) => {
              // if (isAlphaNumeric(event.currentTarget.value)) {
              setWorkspaceAlias(event.currentTarget.value);
              // setRenameKnowledgeSpaceWarning(false);
              setIsChange(true);
              // } else {
              //   setWorkspaceAlias(workspaceAlias);
              //   input.current.value = workspaceAlias;
              //   (event as any).target.value = workspaceAlias;
              //   setRenameKnowledgeSpaceWarning(true);
              //   setIsChange(true);
              // }
            }}
            label={""}
          />
          {nonUniqAlias && workspaceAlias ? (
            <div style={{ color: "red", marginTop: "8px" }}>
              The value: "{workspaceAlias}" is not unique. Please choose
              different one.
            </div>
          ) : null}
          {renameKnowledgeSpaceWarning && workspaceAlias ? (
            <div style={{ color: "red", marginTop: "8px" }}>
              Please use only lower-case letters or numbers
            </div>
          ) : null}
          {workspaceAlias ? null : (
            <p
              className={styles.adminIformation__error}
              style={{ color: "red", marginTop: "8px" }}
            >
              This field can NOT be empty
            </p>
          )}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <span className={styles.buttonContainer__buttons}>
          <Button
            title={"Cancel"}
            color={"white"}
            tabIndex={0}
            onPress={() => handleRenameModal()}
            onKeyPress={() => {
              handleRenameModal();
            }}
            disabled={isLoading}
          />
          <Button
            title={`Apply`}
            color={"blue"}
            tabIndex={1}
            onPress={handleSubmit}
            onKeyPress={() => {
              handleSubmit();
            }}
            processing={isLoading}
            // disabled={isChange ? false : true}
            disabled={!isChange || !workspaceAlias ? true : false}
          />
        </span>
      </div>
    </ModalWindow>
  );
};

export default RenameModal;
