import {
  CopyFromTheme,
  PortalItem,
  QuestionForExample,
  RemovePortalImgRequest,
  ThemeItem,
  UpdatePortalNameRequest,
  UpdateSingleThemeRequest,
  UpdateThemeItemRequest,
  UpdateThemeQuestionsRequest,
  SwitchPortalRequest
} from "../models/portal";

import { LogUserEvent } from "../api/stats-api";
import { IResponse } from "../models/http";
import { getDashboardHost } from "../configs/env";

export const createNewPortal = async (
  workspaceId: string,
  name: string,
  userId: string,
  token: string,
  mode: string
): Promise<PortalItem> => {
  const url = new URL(`${getDashboardHost()}/portals`);

  const reqObj = {
    workspaceId,
    name,
    userId,
    mode
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const portalResp: IResponse<PortalItem> = await res.json();

  if (portalResp.error) {
    throw new Error(portalResp.error.message);
  } else {
    return portalResp.data;
  }
};

export const getPortalsList = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<PortalItem[]> => {
  const url = `${getDashboardHost()}/portals/workspaces/${workspaceId}?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const portalsListRes: IResponse<PortalItem[]> = await res.json();

  if (portalsListRes.error) {
    throw new Error(portalsListRes.error.message);
  } else {
    return portalsListRes.data;
  }
};

export const getPortal = async (
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string
): Promise<PortalItem> => {
  const url = `${getDashboardHost()}/portals/${portalId}/workspaces/${workspaceId}?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const portal: IResponse<PortalItem> = await res.json();
  if (portal.error) {
    throw new Error(portal.error.message);
  } else {
    return portal.data;
  }
};

export const updatePortalName = async (
  workspaceId: string,
  portalId: string,
  name: string,
  userId: string,
  token: string
): Promise<PortalItem> => {
  var url = new URL(`${getDashboardHost()}/portals/update-name`);

  const reqObj: UpdatePortalNameRequest = {
    workspaceId,
    portalId,
    userId,
    name,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const publishPortal = async (
  workspaceId: string,
  portalId: string,
  name: string,
  userId: string,
  token: string
): Promise<PortalItem> => {
  var url = new URL(`${getDashboardHost()}/portals/publish`);

  const reqObj: UpdatePortalNameRequest = {
    workspaceId,
    portalId,
    userId,
    name,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const deletePortal = async (
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string
): Promise<boolean> => {
  const url = new URL(`${getDashboardHost()}/portals`);

  const reqObj = {
    workspaceId,
    id: portalId,
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "DELETE",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const portalsListRes: IResponse<boolean> = await res.json();

  if (portalsListRes.error) {
    throw new Error(portalsListRes.error.message);
  } else {
    return portalsListRes.data;
  }
};

export const uploadPortalBgImg = async (
  data: File,
  theme: "preview" | "publish",
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string,
  mode: string
): Promise<PortalItem> => {
  const url = new URL(`${getDashboardHost()}/portals/upload-image`);

  const formData = new FormData();
  formData.append("image", data);
  formData.append("theme", theme);
  formData.append("workspaceId", workspaceId);
  formData.append("portalId", portalId);
  formData.append("userId", userId);
  formData.append("mode", mode);

  const res = await fetch(<any>url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: formData,
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const uploadPortalImg = async (
  data: File,
  themeName: ThemeItem["name"],
  themeItemKey: "bgImage" | "logoImage" | "bgPortraitImage" | "backgroundImage" | "avatar",
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string,
  mode: string
): Promise<PortalItem> => {
  const url = new URL(`${getDashboardHost()}/portals/update-theme-image`);

  const formData = new FormData();
  formData.append("image", data);
  formData.append("themeName", themeName);
  formData.append("themeItemKey", themeItemKey);
  formData.append("workspaceId", workspaceId);
  formData.append("portalId", portalId);
  formData.append("userId", userId);
  formData.append("mode", mode);

  const res = await fetch(<any>url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: formData,
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const removePortalImg = async (
  fileName: string,
  themeName: ThemeItem["name"],
  themeItemKey: "bgImage" | "logoImage" | "bgPortraitImage" | "backgroundImage" | "avatar",
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string,
  mode: string
): Promise<PortalItem> => {
  var url = new URL(`${getDashboardHost()}/portals/remove-theme-image`);

  const reqObj: RemovePortalImgRequest = {
    fileName,
    themeName,
    themeItemKey,
    workspaceId,
    portalId,
    userId,
    mode
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const copyFromTheme = async (
  themeToCopy: ThemeItem["name"],
  themeToReplace: ThemeItem["name"],
  skipImages: string[],
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string,
  mode: string,
): Promise<PortalItem> => {
  var url = new URL(`${getDashboardHost()}/portals/update-from-theme`);

  const reqObj: CopyFromTheme = {
    themeToCopy,
    themeToReplace,
    skipImages,
    workspaceId,
    portalId,
    userId,
    mode
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const updateThemeItem = async (
  themeName: ThemeItem["name"],
  themeItemKey: string,
  themeItemValue: boolean | string | number,
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string,
  mode: string
): Promise<PortalItem> => {
  var url = new URL(`${getDashboardHost()}/portals/update-theme-item`);

  const reqObj: UpdateThemeItemRequest = {
    themeName,
    themeItemKey,
    themeItemValue,
    workspaceId,
    portalId,
    userId,
    mode
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const updateThemeQuestions = async (
  themeName: ThemeItem["name"],
  themeItemKey: ThemeItem["imageLayout"] | ThemeItem["logoUrl"],
  themeItemValue: QuestionForExample[],
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string
): Promise<PortalItem> => {
  var url = new URL(`${getDashboardHost()}/portals/update-theme-questions`);

  const reqObj: UpdateThemeQuestionsRequest = {
    themeName,
    themeItemKey,
    themeItemValue,
    workspaceId,
    portalId,
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const updateSingleTheme = async (
  themeName: ThemeItem["name"],
  themeItem: ThemeItem,
  workspaceId: string,
  portalId: string,
  userId: string,
  token: string,
  mode: string
): Promise<PortalItem> => {
  var url = new URL(`${getDashboardHost()}/portals/update-single-theme`);

  const reqObj: UpdateSingleThemeRequest = {
    themeName,
    themeItem,
    workspaceId,
    portalId,
    userId,
    mode
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const SwitchPortal = async (
  workspaceId: string,
  portalId: string,
  userId: string,
  mode: string,
  token: string
): Promise<PortalItem> => {
  var url = new URL(`${getDashboardHost()}/portals/update-portal-mode`);

  const reqObj: SwitchPortalRequest = {
    workspaceId,
    portalId,
    userId,
    mode
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<PortalItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};