export enum Roles {
  RoleTeamManager = "TeamManager",
  RoleContentManager = "ContentManager",
  RoleAgent = "Agent",
}

export interface RoleItem {
  id: string;
  workspaceId: string;
  userId: string;
  role: Roles;
}

export interface UpdateRoleRequest {
  id: string;
  workspaceId: string;
  userId: string;
  role: Roles;
}

export const getHumanReadableRole = (role: Roles): string => {
  switch (role) {
    case Roles.RoleTeamManager:
      return "Team Manager";
    case Roles.RoleContentManager:
      return "Content Manager";
    case Roles.RoleAgent:
      return "Agent";
    default:
      return "";
  }
}

export const updatedHumanReadableRole = (role: Roles): string => {
  switch (role) {
    case Roles.RoleTeamManager:
      return "Owner ";
    case Roles.RoleContentManager:
      return "Editor ";
    case Roles.RoleAgent:
      return "Viewer ";
    default:
      return "";
  }
}
export const getErrorStatus = (status: number): string => {
  switch (status) {
    case 100:
      return "Something went wrong";
    case 500:
      return "Content Manager";
    case 101:
      return "Agent";
    default:
      return "";
  }
}