import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useState } from "preact/hooks";

export interface SelectedKappContextActions {
  handleCheckPortalSwitch: (flag: boolean) => void;
  handleHavePortalName: (flag: string) => void;
  PortalSwitch: boolean;
  havePortalName: string;
}

export const SwitchPortalContext =
  createContext<SelectedKappContextActions>(null);

export const useSwitchPortalContext = () => {
  return useContext(SwitchPortalContext);
};

const SwitchPortalContextProvider: FunctionalComponent = ({ children }) => {
  const [portalSwitch, setPortalSwitch] = useState<boolean>();
  const [havePortalName, setHavePortalName] = useState<string>("");

  const handleCheckPortalSwitch = (value: boolean) => {
    setPortalSwitch(value);
  };
  const handleHavePortalName = (value: string) => {
    setHavePortalName(value);
  };
  return (
    <SwitchPortalContext.Provider
      value={{
        havePortalName: havePortalName,
        handleHavePortalName: handleHavePortalName,
        PortalSwitch: portalSwitch,
        handleCheckPortalSwitch: handleCheckPortalSwitch,
      }}
    >
      {children}
    </SwitchPortalContext.Provider>
  );
};

export default SwitchPortalContextProvider;
