import { forwardRef } from "preact/compat";
import styles from "./styles.module.scss";
import { h } from "preact";

interface Iprops {
  name?: string;
  value?: string;
  placeholder?: string;
  width?: string;
  onChange?: any;
  height?: string;
  disabled?: boolean;
  label?: string;
  paddingRight?: string;
  background?: string;
  maxLength?: number;
  onBlur?: any;
  pattern?: string;
  type?: string;
  isLable?: boolean;
  defaultValue?: string;
  onKeyPress?: any;
  fieldBorder?: boolean;
}

const Input = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      defaultValue,
      width,
      height,
      disabled,
      label,
      paddingRight,
      background,
      maxLength,
      onBlur,
      pattern,
      type,
      isLable = true,
      onKeyPress, // Include onKeyPress in the props
      fieldBorder,
    }: Iprops,
    ref?: any
  ) => {
    return (
      <div className={styles.container}>
        {isLable ? (
          <p className={disabled ? styles.labelDisabled : styles.label}>
            {label}
          </p>
        ) : null}

        <input
          ref={ref}
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onKeyPress={onKeyPress} // Use the onKeyPress prop here
          className={disabled ? styles.inputDisabled : styles.input}
          placeholder={placeholder}
          style={{
            width: width,
            height: height,
            paddingRight: paddingRight,
            background: background,
            //hide the number spinner
            border: fieldBorder ? "1px solid red" : "",
            WebkitAppearance: "none",
          }}
          maxLength={maxLength ? maxLength : 1250}
          onBlur={onBlur}
          pattern={pattern}
          type={type}
        />
      </div>
    );
  }
);

export default Input;
